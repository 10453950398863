@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import '~react-multi-carousel/lib/styles.css';

* {
    box-sizing: border-box;
}

body{
    font-family: 'Roboto';
    background-color: #ffffff;
}

.modal-overlay{
    background-color: rgba(0,0,0, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 3;
}

.modal-content{
    width: 329px;
    min-height: 551px;

    border-radius: 32px;
    padding: 22px;
    padding-bottom: 70px;

    z-index: 4;

    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

/* tks GPT4 */
.carousel-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-padding: 0 10px;
  width: 100%;
  scrollbar-width: none; /* Esconde a barra de rolagem no Firefox */

  padding: 20px 0;
}

.carousel::-webkit-scrollbar {
  display: none; /* Esconde a barra de rolagem no Chrome e Safari */
}

.carousel-button {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  height: 40px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  z-index: 1;
}

.carousel-button-dark {
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  height: 40px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  z-index: 1;
}

.black-button {
  background-color: rgba(0, 0, 0, 0.6);
}

.carousel-button-left {
  left: 0px;
}

.carousel-button-right {
  right: 0px;
}

.hidden{
  display: none;
}

  