@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
    box-sizing: border-box;
}

body{
    font-family: 'Roboto';
    background-color: #ffffff;
}

.modal-overlay{
    background-color: rgba(0,0,0, 0.6);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 3;
}

.modal-content{
    width: 329px;
    min-height: 551px;

    border-radius: 32px;
    padding: 22px;
    padding-bottom: 70px;

    z-index: 4;

    background-color: #ffffff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

/* tks GPT4 */
.carousel-container {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-padding: 0 10px;
  width: 100%;
  scrollbar-width: none; /* Esconde a barra de rolagem no Firefox */

  padding: 20px 0;
}

.carousel::-webkit-scrollbar {
  display: none; /* Esconde a barra de rolagem no Chrome e Safari */
}

.carousel-button {
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  height: 40px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 40px;
  z-index: 1;
}

.carousel-button-dark {
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  height: 40px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 40px;
  z-index: 1;
}

.black-button {
  background-color: rgba(0, 0, 0, 0.6);
}

.carousel-button-left {
  left: 0px;
}

.carousel-button-right {
  right: 0px;
}

.hidden{
  display: none;
}

  
